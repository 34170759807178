$('.heroslider').slick({
    autoplay: true,
    infinite: true,
    arrows: false,
    speed: 500,
    fade: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    cssEase: 'linear'
});

$(".zimmer-slider figcaption a").hover(function() {
    console.log('hover');
    $(this).closest('figure').children('a').toggleClass('hover');
});
$(function() {
    $(".zimmer-slider figcaption a").wrapInner('<p/>');
    $(".zimmer-slider figcaption a").prepend('<h3/>');
    // var newheading = $(".zimmer-slider figcaption a h2");
    // newheading.add('h2');
    $(".zimmer-slider figcaption a h3").prepend(function() {
        // return $(this).add('h2');
        return $(this).closest('figure').find('img').attr("alt");
    });






    // var item = $('.zimmer-slider .slickslide');
    // // var  alt = $('img').attr('alt');
    // // slider.find('figure img')
    // //
    // item.append(function () {
    //     var alt = $('.zimmer-slider img').attr('alt');
    //     return $('<h2/>', {
    //         class: 'blocks-gallery-item',
    //         type: String,
    //         value: 'data-fancybox',
    //         html: this.innerHTML
    //     });
    // });

    $('.zimmer-slider').slick({
        autoplay: false,
        infinite: true,
        arrows: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        appendArrows: '.zimmer-slider',
        responsive: [{
                breakpoint: 1025,
                settings: {
                    adaptiveHeight: true,
                    dots: true,
                    arrows: false
                }
            }]
    });

});


$(function() {
    // $('.lightbox li').each(function() {
    //     // var lightboxitem = $(this).find('figure');
    //     var lightboxitem = $('figure', this);
    //     var lightboximgsrc = $('img', lightboxitem).attr('src');
    //     // var imagelink = $();
    //     $(this).replaceTag('<a>', true);
    //     // $(this).replaceTag($('<a>').attr('href='+'"'+lightboximgsrc+'"' +'data-fancybox'), true);
    //     // $(this).append('<a>');
    //
    //     // lightboxitem.wrap('<a href='+'"'+lightboximgsrc+'"' +'data-fancybox>');
    //     // lightboxitem.append('</a>');
    //     // $('.lightbox li').append($('figure'));
    //     // $('.lightbox li').attr('data-fancybox', 'true');
    // });

    var item = $('.lightbox li');

    // item.each(function() {
    item.replaceWith(function() {
        var lightboxitem = $('figure', item);
        var lightboximgsrc = $('img', lightboxitem).attr('src');
        return $('<a/>', {
            class: 'blocks-gallery-item',
            href: lightboximgsrc,
            // data-fancybox: 'true',
            // emptyProperty: {
            //   type: String,
            //   value: 'data-fancybox'
            // },
            // data-fancybox: 'true',
            // data: fancybox,
            // attr: 'href='+'"'+lightboximgsrc+'"' +'data-fancybox>',
            html: this.innerHTML
        });
    });
    $('.lightbox a').attr('data-fancybox', 'gallery');

    $('[data-fancybox="gallery"]').fancybox({
        buttons: [
            // "zoom",
            //"share",
            // "slideShow",
            //"fullScreen",
            //"download",
            // "thumbs",
            "close"
        ]
    });



    // });

    $('.nav-toggle').click(function(e) {
        // console.log('click');
        $('#content').toggleClass('nav-cover');
        $('.main-navigation').toggleClass('visible');
        $(this).toggleClass('change');
        e.stopPropagation();
    });
    $('html').click(function() {
        // console.log('click');
        if ($('.main-navigation').hasClass('visible')) {
            console.log('click');
            $('.main-navigation').removeClass('visible');
            $('.nav-toggle').removeClass('change');
            $('#content').removeClass('nav-cover');
        }
    });

});
